import React from "react";
import { cx } from "~/cva.config";

type CardPropsType = {
  children: React.ReactNode;
  className?: string;
};

export const GridCard = ({
  children,
  cardClassName,
}: {
  children: React.ReactNode;
  cardClassName?: string;
}) => {
  return (
    <Card className={cx("overflow-hidden", cardClassName)}>
      <div className="relative">
        <div className="grid-card absolute inset-0 z-0 h-full w-full" />
        <div className="bg-grid-blue-100/[20%] absolute inset-0 z-0 h-full w-full rotate-0" />
        <div className="isolate">{children}</div>
      </div>
    </Card>
  );
};

export default function Card({ children, className }: CardPropsType) {
  return (
    <div
      className={cx(
        "w-full relative rounded border-none bg-white shadow outline outline-1 outline-slate-800/30",
        className,
      )}
    >
      {children}
    </div>
  );
}
